<template>
    <DefaultCardSlot :showDropdownMenu="false" :cardHeight="cardHeight">
        <ProjectPotentialContent/>
    </DefaultCardSlot>
</template>
<script>
import { defineComponent } from 'vue';

import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import ProjectPotentialContent from '@/modules/project-post-overview/components/ProjectPotentialContent.vue';

export default defineComponent({
  components: {
    DefaultCardSlot,
    ProjectPotentialContent
  },
  props: ['cardHeight'],
});
</script>
<style lang="scss" scoped>
</style>
