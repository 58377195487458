import { defineComponent } from 'vue';
// import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
// import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    name: 'project-details-form-modal',
    emits: ['edit-details', 'on-close'],
    components: {
        BaseModal,
        Button,
    },
    props: ['show', 'title', 'description'],
    data: () => {
        return {
            isDetailsEdited: false,
            titleModel: '',
            descriptionModel: '',
            isUpdating: false,
        };
    },
    watch: {
        titleModel: {
            immediate: true,
            handler() {
                this.checkState();
            }
        },
        descriptionModel: {
            immediate: true,
            handler() {
                this.checkState();
            }
        }
    },
    computed: {},
    created() {
        this.initializeDetails();
    },
    methods: {
        cancel() {
            this.$emit('on-close');
        },
        editDetails() {
            const details = {
                title: this.titleModel,
                description: this.descriptionModel,
            };
            this.$emit('edit-details', details);
        },
        initializeDetails() {
            this.titleModel = this.title;
            this.descriptionModel = this.description;
        },
        checkState() {
            if (this.titleModel !== this.title || this.descriptionModel !== this.description) {
                this.isDetailsEdited = true;
            }
            else {
                this.isDetailsEdited = false;
            }
        }
    }
});
