<template>
    <div class="attachment-upload flex-column ai-c jc-c">
       <inline-svg class="attachment-upload__image" :src="require(`@/assets/images/upload-image.svg`)"></inline-svg>
       <div class="attachment-upload__wrapper flex-row ai-c">
         <inline-svg class="attachment-upload__icon" :src="require(`@/assets/icons/upload-icon.svg`)"></inline-svg>
         <p class="attachment-upload__upload-text defaut-text">Upload</p>
       </div>
    </div>
</template>
<script>
export default {
};
</script>
<style lang="scss" scoped>
.attachment-upload {
    gap: 0;
    &__upload-text {
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0.25px;
    }
    &__image, &__icon {
        object-fit: contain;
    }
    &__icon {
        width: 16px;
        height: 16px;
    }
    &__wrapper {
        gap: .5rem;
    }
}
</style>
