<template>
    <DefaultCardSlot :showDropdownMenu="false" :cardHeight="cardHeight" :hasCustomHeader="true">
      <template #custom-header>
        <div class="header-wrapper flex-row ai-c jc-sb">
          <p class="header-wrapper__title default-text">Attachments</p>
          <!-- NOTE: SHOW CALL TO ACTION WHEN HAS UPLOADS -->
          <!-- <CallToAction label="Edit"/> -->
        </div>
      </template>
      <ProjectWidgetUpload
        :record="form"
        :allowMultiple="true"
        :attachmentSources="attachmentSources"
        :useCustomUploadIcon="true"
        @on-progress-upload="onProgressUpload"
        listType="picture"/>
    </DefaultCardSlot>
</template>
<script>
import ProjectWidgetUpload from '@/core/components/common/forms/upload/ProjectWidgetUpload.vue';
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
// import CallToAction from '@/core/components/ui/CallToAction.vue';

export default {
  components: {
    DefaultCardSlot,
    // CallToAction,
    ProjectWidgetUpload,
  },
  props: ['cardHeight', 'projectAttachments'],
  data() {
    return {
      form: {},
      attachmentSources: [],
    };
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.attachments?.length) {
          this.onUpdateValue(value.attachments);
        }
      }
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      if (this.projectAttachments.length) {
        this.attachmentSources = this.projectAttachments;
      }
    },

    onProgressUpload() {
      this.$emit('on-progress-upload');
    },

    onUpdateValue(attachments) {
      this.$emit('on-update-value', attachments);
    }
  }

};
</script>
<style lang="scss" scoped>
.header-wrapper {
  width: 100%;
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
}
.description-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
</style>
