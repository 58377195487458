import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { PROJECT_POST_STORE } from '@/store/modules/projects/submodules/project-post';
export default defineComponent({
    computed: {
        ...mapGetters(PROJECT_POST_STORE, ['epcRecommendationProjectDetails']),
        indicativeCost() {
            return this.epcRecommendationProjectDetails['indicative-cost'] || '';
        },
    }
});
