<template>
    <DefaultCardSlot :showDropdownMenu="false" :cardHeight="cardHeight" :hasCustomHeader="true">
      <template #custom-header>
        <div class="header-wrapper flex-row ai-c jc-sb">
          <p class="header-wrapper__title default-text">Description</p>
          <CallToAction label="Edit" @handle-click="setOpenProjectDetailsFormModal(true)"/>
        </div>
      </template>
      <p class="description-text default-text">
        {{description}}
      </p>
    </DefaultCardSlot>
</template>
<script>
import { mapActions } from 'vuex';

import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import CallToAction from '@/core/components/ui/CallToAction.vue';
import { PROJECT_POST_STORE } from '@/store/modules/projects/submodules/project-post';

export default {
  components: {
    DefaultCardSlot,
    CallToAction
  },
  props: ['cardHeight', 'description'],
  methods: {
    ...mapActions(PROJECT_POST_STORE, ['setOpenProjectDetailsFormModal']),
  },
};
</script>
<style lang="scss" scoped>
.header-wrapper {
  width: 100%;
  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
  }
}
.description-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
</style>
