import moment from 'moment';
import { defineComponent } from 'vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
// import { PROJECTS_STORE } from '@/store/modules/projects';
const FLEXIBLE = 'flexible';
const SCHEDULE = 'schedule';
export default defineComponent({
    name: 'project-status-modal',
    emits: ['edit-status', 'on-close'],
    components: {
        BaseModal,
        Button,
    },
    props: ['show', 'status'],
    data: () => {
        return {
            isUpdating: false,
            disabledDate(date) {
                return date < moment(new Date()).set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                }).toDate();
            },
            projectType: {
                type: '',
                standardType: '',
                standardDate: [],
            },
            SCHEDULE,
            FLEXIBLE
        };
    },
    watch: {
    // titleModel: {
    //   immediate: true,
    //   handler() {
    //     this.checkState();
    //   }
    // },
    // descriptionModel: {
    //   immediate: true,
    //   handler() {
    //     this.checkState();
    //   }
    // }
    },
    computed: {},
    created() {
        this.initializeStatus();
    },
    methods: {
        cancel() {
            this.$emit('on-close');
        },
        editStatus() {
            if (this.projectType.standardType === FLEXIBLE) {
                this.$emit('edit-status', this.projectType);
                return;
            }
            if (this.projectType.standardDate.length) {
                this.$emit('edit-status', this.projectType);
            }
        },
        initializeStatus() {
            this.projectType.type = this.status;
            this.projectType.standardType = FLEXIBLE;
        },
        selectStandardType(type) {
            this.projectType.standardType = type;
        },
    }
});
