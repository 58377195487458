<template>
    <p class="sponsor-tag default-text">
      Powered by <span>Krystal</span>
    </p>
</template>
<script>
export default {
};
</script>
<style lang="scss" scoped>
.sponsor-tag {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(12, 15, 74, 0.5);

    span {
      font-weight: 600;
      color: #0C0F4A;
    }
}
</style>
