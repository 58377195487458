<template>
    <div class="cta flex-row ai-c cursor-pointer" @click="handleClick">
        <p class="cta__text default-text">{{label}}</p>
        <el-icon :size="iconSize" color="#FFA500"><ArrowRightBold/></el-icon>
    </div>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';

export default {
  emits: ['handle-click'],
  props: {
    label: {
      type: String
    },
    iconSize: {
      type: Number,
      default: 12
    },
    labelSize: {
      type: String,
      default: '14px'
    }
  },
  components: {
    ArrowRightBold
  },
  methods: {
    handleClick() {
      this.$emit('handle-click');
    }
  },
};
</script>
<style lang="scss" scoped>
.cta {
  gap: .5rem;

  &__text {
    font-size: v-bind('$props.labelSize');
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    color: #FFA500;
  }
}
</style>
