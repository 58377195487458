import { Check } from '@element-plus/icons';
import { defineComponent } from 'vue';
export default defineComponent({
    components: {
        Check
    },
    props: ['formData'],
    data() {
        return {
            menus: [
                {
                    isCompleted: true,
                    name: 'Status'
                },
                {
                    isCompleted: false,
                    name: 'Description'
                },
                {
                    isCompleted: false,
                    name: 'Attachments'
                },
            ]
        };
    },
    created() {
        this.checkMenuState();
    },
    watch: {
        formData: {
            immediate: true,
            deep: true,
            handler() {
                this.checkMenuState();
            }
        },
    },
    methods: {
        checkMenuState() {
            const filteredMenu = this.menus.slice(1);
            const [description, attachments] = filteredMenu;
            if (this.formData.description !== null) {
                description.isCompleted = true;
            }
            if (this.formData.attachments.length) {
                attachments.isCompleted = true;
            }
        }
    },
});
