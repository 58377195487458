import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import ImageCarouselBoxComponent from '@/core/components/common/box-cards/ImageCarouselBoxComponent.vue';
import Button from '@/core/components/ui/Button.vue';
import SponsorTag from '@/core/components/ui/SponsorTag.vue';
import { ProjectJobStatusEnum, ProjectStatusesEnum, ProjectTypesEnum } from '@/core/packages/shared-library';
import PostProjectConfirmationModal from '@/modules/project-post/components/modal/PostProjectConfirmationModal.vue';
import ProjectDetailsFormModal from '@/modules/project-post-overview/components/modal/ProjectDetailsFormModal.vue';
import ProjectStatusModal from '@/modules/project-post-overview/components/modal/ProjectStatusModal.vue';
import PostProjectDescriptionWidget from '@/modules/project-post-overview/components/PostProjectDescriptionWidget.vue';
import PostProjectOverviewMenu from '@/modules/project-post-overview/components/PostProjectOverviewMenu.vue';
import PostProjectUploadWidget from '@/modules/project-post-overview/components/PostProjectUploadWidget.vue';
import ProjectOverviewPotentialWidget from '@/modules/project-post-overview/components/ProjectOverviewPotentialWidget.vue';
import ProjectPostStatusWidget from '@/modules/project-post-overview/components/ProjectPostStatusWidget.vue';
import PropertyDetailsOverviewWidget from '@/modules/project-post-overview/components/PropertyDetailsOverviewWidget.vue';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { PROJECT_POST_STORE } from '@/store/modules/projects/submodules/project-post';
import { PROPERTY_STORE } from '@/store/modules/property-details';
const SCHEDULE = 'schedule';
export default defineComponent({
    components: {
        Button,
        SponsorTag,
        ImageCarouselBoxComponent,
        PropertyDetailsOverviewWidget,
        PostProjectDescriptionWidget,
        ProjectOverviewPotentialWidget,
        ProjectPostStatusWidget,
        PostProjectUploadWidget,
        PostProjectOverviewMenu,
        PostProjectConfirmationModal,
        ProjectDetailsFormModal,
        ProjectStatusModal
    },
    data() {
        return {
            property: null,
            /* eslint-disable global-require */
            images: [
            // {
            //   src: `${require('@/assets/images/house-sample-1.jpg')}`,
            // },
            // {
            //   src: `${require('@/assets/images/house-sample-2.jpg')}`,
            // },
            ],
            previewImages: [
            // `${require('@/assets/images/house-sample-1.jpg')}`,
            // `${require('@/assets/images/house-sample-2.jpg')}`,
            ],
            /* eslint-disable global-require */
            formData: {
                propertyId: null,
                projectJobStatusId: ProjectJobStatusEnum.AWAITING_QUOTES,
                projectStatusId: ProjectStatusesEnum.READY_FOR_HIRE,
                minBudget: 0,
                maxBudget: 0,
                attachments: [],
                categories: [],
                postcode: null,
                latitude: 0,
                longitude: 0,
                name: null,
                description: null,
                type: ProjectTypesEnum.STANDARD,
                recommendationData: null,
            },
            statusTypeData: null,
            isSubmittingProject: false
        };
    },
    computed: {
        ...mapGetters(PROPERTIES_PROJECT_POST_STORE, [
            'getPostProjectConfirmationModal',
        ]),
        ...mapGetters(PROJECT_POST_STORE, ['epcRecommendationProjectDetails', 'openProjectDetailsFormModal', 'openProjectStatusModal']),
        title() {
            return this.epcRecommendationProjectDetails['improvement-id-text'];
        },
        description() {
            return this.epcRecommendationProjectDetails['improvement-descr-text'] === '' || this.epcRecommendationProjectDetails['improvement-descr-text'] === null
                ? this.epcRecommendationProjectDetails['improvement-id-text']
                : this.epcRecommendationProjectDetails['improvement-descr-text'];
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(PROPERTY_STORE, ['getProperty']),
        ...mapActions(PROJECT_POST_STORE, ['saveProject', 'setOpenProjectDetailsFormModal', 'setEpcRecommendationProjectDetails', 'setOpenProjectStatusModal']),
        ...mapActions(PROPERTIES_PROJECT_POST_STORE, [
            'setPostProjectConfirmationModal',
        ]),
        async initialize() {
            const { propertyId } = this.$route.params;
            if (propertyId) {
                this.property = await this.getProperty(propertyId);
                const { addressData, postcode } = this.property;
                const addressDataObj = JSON.parse(addressData);
                const { latlng } = addressDataObj;
                const [latitude, longitude] = latlng;
                this.formData.propertyId = parseInt(propertyId, 10);
                this.formData.postcode = postcode;
                this.formData.latitude = `${latitude}`;
                this.formData.longitude = `${longitude}`;
                this.formData.name = this.title;
                this.formData.description = this.description;
                this.formData.recommendationData = JSON.stringify(this.epcRecommendationProjectDetails);
            }
        },
        async createNewProject() {
            const project = this.formData;
            this.isSubmittingProject = true;
            await this.saveProject(project)
                .then(async (response) => {
                if (response) {
                    this.$notify.success({
                        title: 'Project Creation Successful',
                        message: 'Successfully posted the project',
                    });
                    this.$router.push(`/projects/${response.refId}/project-details/overview`);
                }
            })
                .catch((e) => {
                this.$notify.error({
                    title: 'Project Creation Failed',
                    message: e.data.message || 'Error occurred when creating the project.',
                });
            })
                .finally(() => {
                this.isSubmittingProject = false;
                this.setPostProjectConfirmationModal(false);
            });
        },
        onUpdateAttachments(attachments) {
            this.formData.attachments = attachments;
        },
        editDetails(event) {
            const { epcRecommendationProjectDetails } = this;
            const newEpcRecommendationData = {
                ...epcRecommendationProjectDetails,
                'improvement-id-text': event.title
            };
            this.setEpcRecommendationProjectDetails(newEpcRecommendationData);
            this.formData.name = event.title;
            this.formData.description = event.description;
            this.setOpenProjectDetailsFormModal(false);
        },
        editStatus(event) {
            if (event.standardType === SCHEDULE) {
                const [start, end] = event.standardDate;
                this.formData.startDate = start;
                this.formData.endDate = end;
            }
            this.statusTypeData = event;
            this.setOpenProjectStatusModal(false);
        }
    },
});
