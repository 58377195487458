import { defineComponent } from 'vue';
import DefaultDynamicStatusModalContent from '@/core/components/common/modals/DefaultDynamicStatusModalContent.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
export default defineComponent({
    name: 'cancel-post-modal',
    emits: ['on-confirm', 'on-cancel'],
    props: ['loading'],
    components: {
        DefaultDynamicStatusModalContent,
        BaseModal
    },
    data() {
        return {
            type: 'postProjectConfirmation',
            content: {
                hasTwoButton: true,
                /* eslint-disable global-require */
                icon: `${require('@/assets/icons/post-project-confirmation-icon.svg')}`,
                /* eslint-disable global-require */
                statusText: 'Let’s now get you some estimates',
                hasDescription: true,
                descriptionText: 'All projects are subject to iknowa ',
                spanText: 'Terms of Service',
                buttonActiveLabel: 'post',
                buttonInActiveLabel: 'cancel'
            }
        };
    },
    methods: {
        onConfirm() {
            this.$emit('on-confirm');
        },
        onCancel() {
            this.$emit('on-cancel');
        }
    }
});
