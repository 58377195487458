import { defineComponent } from 'vue';
import AttachmentUpload from '@/core/components/ui/AttachmentUpload.vue';
import { AUTH_TOKEN } from '@/core/constants';
import { DocumentFileTypes, ImageFileTypes } from '@/core/helpers/file.helper';
import { getLocalStorageWithExpiry } from '@/core/utils/common';
export default defineComponent({
    name: 'upload-form',
    emits: ['on-progress-upload', 'on-upload-success', 'custom-fn-remove-file'],
    props: {
        uploadContainerClass: {
            type: String,
            default: '',
            required: false
        },
        useCustomUploadIcon: {
            type: Boolean,
            default: true,
            required: false
        },
        useClipUploadIcon: {
            type: Boolean,
            default: false,
            required: false
        },
        listType: {
            type: String,
            default: '',
            required: false
        },
        record: {
            type: Object,
            required: true,
        },
        attachmentSources: {
            type: Array,
            required: true
        },
        allowMultiple: {
            type: Boolean,
            default: false,
            required: true
        },
        uploadUrl: {
            type: String,
            default: '',
            required: false
        },
        hasCustomFnRemoveFile: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    components: {
        AttachmentUpload
    },
    data: () => {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            failedUpload: false,
            imageFileTypes: ImageFileTypes,
            documentFileTypes: DocumentFileTypes,
            fileList: [],
            enableMultiple: false,
            actionUrl: '',
            isUploading: false
        };
    },
    created() {
        const component = this;
        const { formEntity, attachmentSources } = component;
        this.actionUrl = this.uploadUrl || `${this.apiUrl}/upload`;
        if (attachmentSources && attachmentSources.length) {
            component.fileList = attachmentSources.map((attachment) => {
                const { originalName } = attachment;
                return {
                    ...attachment,
                    name: originalName,
                    isLoading: false,
                    isDeleting: false
                };
            });
            formEntity.attachments = component.fileList;
        }
        if (!formEntity.attachments) {
            formEntity.attachments = [];
        }
    },
    computed: {
        formEntity() {
            return this.record;
        },
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        }
    },
    methods: {
        onBeforeUpload(file) {
            this.isUploading = true;
            const name = file.name || '';
            const ext = name.split('.').pop();
            const imageTypes = this.imageFileTypes;
            const fileTypes = this.documentFileTypes;
            let allow = false;
            this.failedUpload = false;
            if (imageTypes.indexOf(ext) !== -1) {
                allow = true;
            }
            if (!allow && fileTypes.indexOf(ext) !== -1) {
                allow = true;
            }
            if (!allow) {
                this.failedUpload = true;
            }
            return allow;
        },
        fileIsImage(file) {
            let { attachment: filename } = file;
            const { name } = file;
            const imageTypes = this.imageFileTypes;
            if (!filename) {
                filename = name;
            }
            if (!filename)
                return false;
            const extension = filename.split('.')[filename.split('.').length - 1];
            return imageTypes.includes(extension.toLowerCase());
        },
        async handleRemoveFile(file) {
            this.isUploading = false;
            const component = this;
            const currentFile = file;
            const { response } = file;
            const { formEntity, fileList } = component;
            const { attachments } = formEntity;
            const attachment = response?.attachment;
            let newList = [];
            currentFile.isDeleting = true;
            if (!attachment) {
                newList = fileList.filter((item) => item.attachment !== file.attachment);
                component.fileList = newList;
                component.formEntity.attachments = attachments.filter((a) => a.attachment !== file.attachment);
            }
            if (attachment) {
                newList = fileList.filter((item) => item.uid !== file.uid);
                component.fileList = newList;
                component.formEntity.attachments = attachments.filter((a) => a.attachment !== attachment);
            }
            if (this.hasCustomFnRemoveFile) {
                this.$emit('custom-fn-remove-file', file);
            }
        },
        async updateFileOnSuccess(event, file, fileList) {
            const { formEntity } = this;
            const currentFile = file;
            const { response } = currentFile;
            this.fileList = fileList;
            currentFile.src = response.src;
            currentFile.isLoading = false;
            delete response.src;
            formEntity.attachments.push(response);
            this.$emit('on-upload-success');
        },
        onProgressUpload(event, file) {
            // eslint-disable-next-line no-param-reassign
            file.isLoading = true;
            this.$emit('on-progress-upload');
        },
        handleErrorFiles(err) {
            this.$notify.error({
                title: 'Upload Error',
                message: err || 'Uploading files error.'
            });
        }
    }
});
