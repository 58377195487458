import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import DefaultCardSlot from '@/core/components/slots/DefaultCardSlot.vue';
import CallToAction from '@/core/components/ui/CallToAction.vue';
import { PROJECT_POST_STORE } from '@/store/modules/projects/submodules/project-post';
const SCHEDULE = 'schedule';
export default defineComponent({
    components: {
        DefaultCardSlot,
        CallToAction
    },
    props: ['cardHeight', 'status'],
    data() {
        return {
            SCHEDULE,
            startDate: '',
            endDate: ''
        };
    },
    computed: {},
    watch: {
        status: {
            immediate: true,
            deep: true,
            handler() {
                if (this.status?.standardType === SCHEDULE) {
                    this.setDate();
                }
            }
        },
    },
    methods: {
        ...mapActions(PROJECT_POST_STORE, ['setOpenProjectStatusModal']),
        setDate() {
            const [start, end] = this.status?.standardDate;
            this.startDate = start;
            this.endDate = end;
        },
    },
});
